.countryDropDown ul li:hover {
    background-color: rgba(243 244 246 / 1);
    cursor: pointer;
}
.countryDropDown ul li.selected {
    background-color: rgba(243 244 246 / 1);
}
.defaultCheckbox p{
    @apply inline m-0 !important;
}

.radioLabel {
    @apply py-[0.625rem] px-[1.25rem] w-full max-w-[calc(100%/2-0.75rem)]  rounded-[5px] flex items-center justify-between flex-row-reverse text-[#999]  font-sans cursor-pointer
}
.radioLabelLowScore.radioLabel {
    @apply border border-[#999] my-2 md:mx-8 w-full md:w-auto md:px-8 py-3 min-w-[15rem];
    max-width: none;
}
.radioLabelLowScore.radioLabel input{
    @apply mx-2
}

.radioLabelActive {
    @apply border border-[#0995DD] text-[#0995DD] font-bold !important;
}

.radioLabel > input {
    @apply focus:ring-0 focus:ring-offset-0 w-[18px] h-[18px]
}


.radioLabel > input:checked {
    @apply border-[#0995DD] bg-[#fff] !important;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%230995DD' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}


.radioLabelDma > input {
    @apply focus:ring-0 focus:ring-offset-0
}

.currencyDropdown label{
    @apply md:bg-[#F2F7F9];
}