.loader {
    position: absolute;
    top:0;
    left: 50%;
    transform: translateX(-50%);
}

.buttonLoading {
    width: 80px;
    background: white;
    pointer-events: none;
    height: 52px;
    margin: 0 auto;
    display: block;
}

.buttonNormal {
    height: 52px;
}
